@charset 'utf-8';
@import '../_env.scss';

.page-sitemap {
	.link-list-strong + .link-list-strong {
		margin-top: 30px;
		padding-top: 30px;
		border-top: 1px solid #ccc;
	}
}
